
.global-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    outline: none;
    background-color:   rgba(38,38,38,0.7);
  }
  
  .global-container:focus {
    outline: none;
  }
  .global-custom-container {
    position: relative;
    width: 100%;
    height: 100%;
    transition-duration: 300ms;
    background-color: #ffffff;
    border: none;
    border-radius: 0.375rem;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    outline: none;
    display: flex;
    flex-direction: column;
  }
  
  /* Responsive widths */
  @media (min-width: 640px) {
    .global-custom-container {
      width: 66.66667%; /* md:w-4/6 */
    }
  }
  
  @media (min-width: 768px) {
    .global-custom-container {
      width: 50%; /* lg:w-3/6 */
    }
  }
  
  @media (min-width: 1024px) {
    .global-custom-container {
      width: 40%; /* xl:w-2/5 */
    }
  }
  
  /* Responsive heights */
  @media (min-width: 768px) {
    .global-custom-container {
      height: auto; /* lg:h-auto */
    }
  }
  
  @media (min-width: 640px) {
    .global-custom-container {
      height: auto; /* md:h-auto */
    }
  }
  
  .global-custom-container:focus {
    outline: none;
  }
  
  .global-custom-container.show-modal {
    transform: translateY(0);
    opacity: 1;
  }
  
  .global-custom-container.hide-modal {
    transform: translateY(-100%);
    opacity: 0;
  }
  
.global-boxmodal{
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border-radius: 0.375rem 0.375rem 0 0; 
    justify-content: center;
    position: relative;
    border-bottom: 1px solid;
    transition: transform 0.3s ease, opacity 0.3s ease;
 
}
/* .boxmodal .show-modal {
  opacity: 1;
  transform: translateY(0);
}

.boxmodal  .hide-modal {
  opacity: 0;
  transform: translateY(-100%);
} */
.global-btn{
  padding: 0.25rem; 
  border: none;
  transition: opacity 0.3s ease;
  position: absolute; 
  left: 1.125rem;
  cursor: pointer;
}
 
  .global-body{
    display: flex;
  position: relative;
  padding: 1.5rem; 
  flex: 1; 
  flex-direction: column;
  gap:15px;


  }
  
  .global-footer{
    display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%; 

  }
  .global-custom-text {
    font-size: 1.25rem; 
    font-weight: 600; 
  }
  
  /* ////addfriend css */
  .global-Add{
    height:40px;
    background-color:#0a53e4 ;
    font-weight:500;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 18px;
    cursor:pointer;
  }
  h6{
    color: red;
    margin-right: 5px;
  }