@import url('https://fonts.googleapis.com/css2?family=Moon+Dance&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('dark_mode_colors_resources.css');

*, ::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  /* font-family: sohne-var, "Helvetica Neue", Arial, sans-serif; */
  color: #000;
}

input, textarea, select { font-family: 'Poppins', sans-serif; }

body{
  background-color: #fefdff;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: 'Poppins', sans-serif;
}

.full-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightpink;
  border: none;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
}

.default-btn{
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightpink;
  border: none;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
}

a{
  color: cornflowerblue;
  font-weight: 300;
}

input{
  border: 1px solid black;
  outline: none;
}

.full-width-line{
  width: 100%;
  height: 1px;
  background-color: #e5e7ed;
}
/* ::-webkit-scrollbar{
  width: 0.75rem;
}
::-webkit-scrollbar-track{
  background-color: transparent;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb{
  background: rgba(44, 43, 46, 0.501);
  border: 0.15em solid #fefdff;
  border-radius: 100px;
} */

::-webkit-scrollbar {
  width: 8px;
}
 
::-webkit-scrollbar-track {
  background-color: #f0e9e9;
  border-left: 1px solid #e9e5e5;
}
 
::-webkit-scrollbar-thumb {
  background-color: #335ddc;
  border-radius: 100px;
}

.heading-supporter{
  margin-top: 10px;
}